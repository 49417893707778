




































import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { IconsType, Language } from "@/types";
import { mdiTranslate } from "@mdi/js";
@Component({})
export default class LangSelect extends Vue {
  @Prop({ default: false }) readonly text!: string;
  @Prop(Boolean) readonly dark!: boolean;
  private icons: IconsType = {
    translate: mdiTranslate
  };
  private languages: Array<Language> = [
    {
      locale: "en",
      img: "@/assets/nuxeo_images/b8ff039d-5980-4fa0-bbe0-91d402f8d79d.webp"
    },
    {
      locale: "ru",
      img: "@/assets/nuxeo_images/08b33f6e-6983-4aec-8a20-a18c9bb3927e.webp"
    }
  ];
  get selected() {
    return this.languages.findIndex((lang: Language) => {
      return lang.locale === this.$root.$i18n.locale;
    });
  }
  @Emit("LANG-CHANGED")
  public async onClick(locale: string) {
    this.$root.$i18n.locale = locale;
    this.$vuetify.lang.current = locale;
    await this.$store.dispatch("AppModule/switchLanguage");
  }
}
